<template>
	<page-container
		class="index"
		:class="{
			'has-landscape-video': page.vimeoIDLandscape,
			'has-portrait-video': page.vimeoIDPortrait,
		}"
		:page="page"
	>
		<heading-image
			v-if="page.headingImage && !(page.vimeoIDLandscape || page.vimeoIDPortrait)"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		/>

		<video-header v-if="page.vimeoIDLandscape" :video="page.vimeoIDLandscape" type="landscape" />
		<video-header v-if="page.vimeoIDPortrait" :video="page.vimeoIDPortrait" type="portrait" />

		<main-content
			v-if="!page.introblock"
			:title="page.title || page.header"
			:content="page.content"
			:intro-image="{
				image: page.introductionImage,
				imageWebp: page.introductionImageWebp,
			}"
		/>

		<intro-block v-if="page.introblock" :item="page.introblock" />

		<section class="location-section">
			<location-slider
				:items="locationsData"
				:filters="types"
				:header="defaults[locale].homepage.sectionLocationsTitle"
				:content="defaults[locale].homepage.sectionLocationsContent"
			/>
		</section>

		<contentblocks-slider :items="contentblocksData" />

		<packages-slider
			v-if="defaults[locale].homepage.showSpecialsSlider"
			:items="packagesData"
			:header="defaults[locale].homepage.sectionPackagesTitle"
			:content="defaults[locale].homepage.sectionPackagesContent"
			:image="defaults[locale].homepage.sectionPackagesImage"
		/>

		<contentblocks :items="page.contentblocks" />

		<usp-block v-if="page.uSPBlock" :header="page.uSPBlock.header" :items="page.uSPBlock.items" />

		<magazine :items="magazineData.slice(0, 3)" />

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:items="page.frequentlyAskedQuestion.faqitems"
			:header="page.frequentlyAskedQuestion.header"
		/>

		<social-section
			v-if="page && page.showInstagramFeed"
			:image="defaults[locale].homepage.sectionSocialmediaImage"
			:header="defaults[locale].homepage.sectionSocialmediaTitle"
			:snapwidget-id="defaults[locale].website.snapwidgetId"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

useRemoveFormitable();

const { data: locationsData } = await useWebsiteFetch('locations', {
	query: { language: locale.value },
	key: `${locale.value}/locations`,
});

const { data: magazineData } = await useWebsiteFetch('magazine', {
	query: { language: locale.value },
	key: `${locale.value}/magazine`,
});

const { data: packagesData } = await useWebsiteFetch('packages', {
	query: { language: locale.value },
	key: `${locale.value}/packages`,
});

const { data: contentblocksData } = await useWebsiteFetch('contentblocks', {
	query: { language: locale.value },
	key: `${locale.value}/contentblocks`,
});

const types = [...new Set(locationsData.value.map(({ type }) => type))];
</script>

<style lang="scss" scoped>
.location-section {
	margin: 0 auto;
	padding: 50px 0 100px;
	background: var(--secondary-background-color);
}

.slider-overlay {
	position: absolute;
	inset: 0;
	z-index: 2;
	background: rgb(0 0 0 / 25%);
}

.hero-content {
	position: absolute;
	max-width: 1400px;
	z-index: 10;
	left: 0;
	right: 0;
	width: auto;
	bottom: 50%;
	margin: 0 auto -70px;
	text-align: center;
	color: #fff;
	letter-spacing: 2px;

	:deep(span) {
		color: #fff;
		font-size: 4.4rem;
		font-family: var(--heading-font-family);
		font-weight: 700;
		margin: 0 0 10px;
		width: 100%;
		display: inline-block;
		line-height: 1;
	}

	:deep(p) {
		font-size: 1.8em;
	}
}

@media (max-width: 1080px) {
	.intro-image {
		display: none;
	}
}

@media (max-width: 880px) {
	.hero-content {
		:deep(span) {
			font-size: 3.4rem;
		}

		:deep(p) {
			font-size: 1.6em;
			padding: 0 10px;
		}
	}
}
</style>
