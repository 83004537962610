<template>
	<div>
		<div class="row">
			<div class="columns column12">
				<div class="slider-filters align-right">
					<p>
						<span v-for="(city, index) in cities" :key="city">
							<a
								href="#"
								class="filter-button"
								:class="{ active: filter.city === city }"
								@click.prevent="changeFilter('city', city)"
								>{{ city }}</a
							>
							<span v-if="index < cities.length - 1" class="divider">|</span>
						</span>
					</p>
				</div>
			</div>
		</div>
		<div class="row row-filter">
			<div class="columns column3 align-left">
				<h3>{{ header }}</h3>
				<div v-parse-links v-html="content" />
				<div class="filter-buttons-bottom">
					<filter-buttons
						:items="filters"
						:filter="filter.type"
						@change-filter="changeFilter('type', $event)"
					/>
				</div>
			</div>
			<div class="columns column9">
				<client-only fallback-tag="span" fallback="">
					<bc-carousel
						ref="locationsCarousel"
						class="location-slider"
						:mouse-drag="false"
						:breakpoints="breakpoints"
						:model-value="1"
						snap-align="start"
					>
						<template #addons>
							<bc-navigation class="slider-navigation">
								<template #next>
									<div class="slider-button slider-next" />
								</template>
								<template #prev>
									<div class="slider-button slider-prev" />
								</template>
							</bc-navigation>
						</template>
						<bc-slide v-for="item in itemsFiltered" :key="item.ID" class="location-item slider-item active">
							<div class="image-holder">
								<a :href="item.gmapsUrl" target="_blank" rel="noopener noreferrer" class="maps-link" />
								<a :href="item.url" target="_blank" rel="noopener noreferrer">
									<div class="image">
										<picture>
											<source :srcset="item.imageWebp" type="image/webp" />
											<source :srcset="item.image" />
											<img :src="item.image" :alt="item.imageAlt" />
										</picture>
									</div>
								</a>
							</div>
							<a :href="item.url" target="_blank" rel="noopener noreferrer">
								<div class="content">
									<p>{{ item.address }}</p>
									<h4>{{ item.header }}</h4>
								</div>
							</a>
						</bc-slide>
					</bc-carousel>
				</client-only>
			</div>
		</div>
	</div>
</template>

<script setup>
const props = defineProps({
	header: { type: String, default: '' },
	content: { type: String, default: '' },
	items: { type: Array, default: () => [] },
	filters: { type: Array, default: () => [] },
});

const locationsCarousel = ref(null);
const cities = ref(null);
const filter = reactive({
	type: 'showAll',
	city: 'showAll',
});

const breakpoints = {
	700: {
		itemsToShow: 2,
	},
	1080: {
		itemsToShow: 3.5,
	},
};

watchEffect(() => {
	cities.value = [...new Set(props.items.map(({ city }) => city))];
});

const changeFilter = (type, value) => {
	filter[type] = value;
	locationsCarousel.value.slideTo(1);
};

const itemsFiltered = computed(() => {
	let result = null;

	if (filter.type === 'showAll' && filter.city === 'showAll') {
		result = props.items;
	} else if (filter.type === 'showAll') {
		result = [...props.items].filter((item) => item.city === filter.city);
	} else if (filter.city === 'showAll') {
		result = [...props.items].filter((item) => item.type === filter.type);
	} else {
		result = [...props.items].filter((item) => item.type === filter.type && item.city === filter.city);
	}

	if (locationsCarousel.value) {
		locationsCarousel.value.restartCarousel();
	}

	return result;
});
</script>

<style lang="scss" scoped>
.location-section {
	.row-filter {
		flex-flow: row wrap;
		align-items: flex-start;
	}

	.column9 {
		padding-left: 50px;
	}

	.column3 {
		padding-right: 60px;
		padding-bottom: 300px;
		position: relative;
		border-right: 1px solid #3c3834;

		.filter-buttons-bottom {
			position: absolute;
			bottom: 0;
			left: 20px;
		}
	}

	.slider-filters {
		font-size: var(--body-font-size);

		.divider {
			padding: 0 7px;
		}
	}

	h3 {
		font-family: var(--body-font-family);
		margin: 0 0 20px;
		color: var(--cta-color-blue);
		max-width: 300px;
	}

	a {
		color: var(--body-color);
		text-decoration: none;
		text-transform: uppercase;
	}
}

.location-slider {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: stretch;
	overflow: hidden;
	position: relative;
	padding: 0 0 100px;

	.content {
		text-align: left;
		padding: 10px 0;
	}
}

/* stylelint-disable selector-class-pattern */
:deep(.carousel__viewport) {
	width: 100%;
}

:deep(.slider-navigation) {
	position: absolute;
	bottom: 0;
	left: 0;
	top: unset;
	transform: unset;
	width: 67px;
	height: 67px;
	margin: 0;
	padding: 0;

	&.carousel__next {
		left: unset;
		right: 0;
	}
}
/* stylelint-enable selector-class-pattern */

.image-holder {
	position: relative;

	a {
		z-index: 1;
		position: relative;

		&::after {
			display: none;
		}
	}
}

.slider-item {
	display: none;
	width: 30%;
	padding: 0 3% 0 0;
	flex-flow: column wrap;
	position: relative;
	justify-content: flex-start;
	align-items: flex-start;

	.maps-link {
		background: url('~/assets/images/location-maps-pointer.png') no-repeat center center;
		width: 55px;
		height: 55px;
		background-size: 55px;
		cursor: pointer;
		position: absolute;
		bottom: 10px;
		left: 0;
		z-index: 2;
	}

	&.active {
		display: flex;
	}

	.image {
		width: 100%;

		img {
			clip-path: polygon(50% 13%, 100% 30%, 100% 100%, 0% 100%, 0% 30%);
			overflow: hidden;
			aspect-ratio: 68 / 102;
		}
	}

	h4 {
		font-family: var(--body-font-family);
		margin: 0;
		color: var(--body-color);
		font-weight: 800;
	}

	p {
		font-family: var(--body-font-family);
		color: var(--body-color);
		margin: 0;
		font-weight: 500;
		text-transform: none;
	}
}

@supports (-webkit-mask-image: url('#mask')) or (mask-image: url('#mask')) {
	.slider-item {
		.image {
			img {
				clip-path: none;
				mask-image: url('~/assets/images/locations-shape-mask.png');
				mask-size: 100%;
			}
		}
	}
}

@media (max-width: 1080px) {
	.location-section {
		.slider-filters {
			text-align: center;
			margin: 0 0 20px;
		}

		.column3 {
			width: 100%;
			text-align: center;
			padding: 0 20px;
			max-width: 600px;
			margin: 0 auto;
			border: none;

			.filter-buttons-bottom {
				position: relative;
				inset: unset;
				margin: 0 0 50px;
			}
		}

		.filter-nav {
			margin: 0 auto 20px;
			justify-content: center;

			> div {
				text-align: center;
			}
		}

		h3 {
			margin: 0 auto 20px;
		}
	}
}

@media (max-width: 880px) {
	.location-section {
		.text-button {
			position: relative;
			left: auto !important;
			bottom: auto;
			justify-content: flex-start;
		}
	}
}

@media (max-width: 700px) {
	.location-slider {
		padding: 0 0 70px;
	}

	.slider-item {
		margin: 0;

		.maps-link {
			left: 20px;
			bottom: 40px;
		}
	}

	.image-holder {
		padding: 0 15px;
	}

	.location-section {
		a {
			padding: 0 15px;
		}

		.column9 {
			border: none;
			padding-left: 20px;
		}
	}
}
</style>
